<template>
  <div class="pb-3">
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div>
            <h1><strong>{{$t('demand_detail.title')}}</strong></h1>
          </div>
        </b-col>
        <b-col md="12">
          <div>
            <small>{{$t('demand_detail.under_title11')}}</small>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Spacer -->
    <!-- <hr class="invoice-spacing"> -->
    <!-- {{ validOutput }} -->
    <b-card-body class="invoice-padding pt-0">
      <div class="d-flex justify-content-center text-center mb-1">
        <b-row>
          <h6 class="mb-25">
            {{ validOutput ? validOutput.mois : '' }}
            {{ validOutput ? validOutput.annee : '' }}
          </h6>
        </b-row>
      </div>
    </b-card-body>

    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong> {{$t('demand_detail.info_guide')}} </strong>
          <!-- <b-button
            v-if="showCheckButtons"
            v-b-tooltip.hover.top.v-dark="'Valider la section'"
            variant="light"
            class="btn-icon"
            size="sm"
            @click="selectAll()"
          >
            <i class="las la-check iconSmall" />
          </b-button> -->
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.identity === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.id_guide')}}&nbsp; </span>
            <strong>
              {{
                validOutput.user.identity != null
                  ? validOutput.user.identity
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.nationalite" class="col-6"
                @change="update_object('nationalite', validOutput.nationalite)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.nationalite, 'nationalite')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.nom === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.name_guide')}}&nbsp; </span>
            <strong class="my-auto">
              {{
                validOutput.user.profile.nom_promoteur  != null
                  ? validOutput.user.profile.nom_promoteur 
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.nom" class="col-6"
                @change="update_object('nom', validOutput.nom)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.nom, 'nom')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.prenoms === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.firstname_guide')}}&nbsp; </span>
            <strong>
              {{
                validOutput.user.profile.prenoms_promoteur  != null
                  ? validOutput.user.profile.prenoms_promoteur 
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.prenoms" class="col-6"
                @change="update_object('prenoms', validOutput.prenoms)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.prenoms, 'prenoms')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if=" check_object.departement_adresse_residence===true"
            class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.department')}}&nbsp; </span>
            <strong class="my-auto">
              {{
                validOutput.user.profile.departement != null
                  ? validOutput.user.profile.departement
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.departement_adresse_residence" class="col-6" @change="
                update_object(
                  'departement_adresse_residence',
                  validOutput.departement_adresse_residence
                )
                " /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.departement_adresse_residence,
                    'departement_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.commune_adresse_residence === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> Commune :&nbsp; </span>
            <strong class="my-auto">
              {{
                validOutput.user.profile.commune != null
                  ? validOutput.user.profile.commune
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.commune_adresse_residence" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.commune_adresse_residence,
                    'commune_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.commune_adresse_residence === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> Arrondissement:&nbsp; </span>
            <strong class="my-auto">
              {{
                validOutput.arrondissement != null
                  ? validOutput.arrondissement
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.commune_adresse_residence" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.commune_adresse_residence,
                    'commune_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.adresse === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.address_guide')}}&nbsp; </span>
            <strong>
              {{
                validOutput.user.profile.adresse_etablissement != null
                  ? validOutput.user.profile.adresse_etablissement
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.nationalite" class="col-6"
                @change="update_object('nationalite', validOutput.nationalite)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.nationalite, 'nationalite')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.telephone === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> {{$t('demand_detail.telephone')}}&nbsp; </span>
            <strong>
              {{
                validOutput.telephone_etablissement != null
                  ? validOutput.telephone_etablissement
                  : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.user.profile.telephone_promoteur" class="col-6"
              @change="update_object('telephone', validOutput.user.profile.telephone_promoteur)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
              @mouseover="hoverTooltip(check_object.telephone, 'telephone')">
              <i class="las la-clipboard-check iconSmall" />
            </b-button> -->
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <!-- <i v-if="check_object.email === true" class="las la-dot-circle icon_primary my-auto" /> -->
            <span class="my-auto"> Email :&nbsp; </span>

            <strong class="my-auto">
              {{
                validOutput.user.profile.email_promoteur != null ? validOutput.user.profile.email_promoteur : '-'
              }}
            </strong>
            <!-- <b-form-input v-else v-model="validOutput.email" class="col-6"
              @change="update_object('email', validOutput.email)" /> -->
            <!-- tooltip -->
            <!-- <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
              @mouseover="hoverTooltip(check_object.email, 'email')">
              <i class="las la-clipboard-check iconSmall" />
            </b-button> -->
          </span>
        </b-col>
      </b-row>
    </b-card-body>

    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong> III-{{$t('demand_detail.info_demand')}}</strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1">
          {{$t('demand_detail.reference')}}&nbsp;
          <strong>
            {{
              validOutput.data.demande != null ? validOutput.data.demande : '-'
            }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          {{$t('demand_detail.type_guide')}}&nbsp;
          <strong>
            {{
              validOutput.data.type != null ? validOutput.data.type : '-'
            }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          {{$t('demand_detail.accord')}}&nbsp;
          <strong>
            {{
              validOutput.data.accord_sur_deontologie != null
                ? validOutput.data.accord_sur_deontologie === true ? 'Oui' : 'Non'
                : '-'
            }}
          </strong>
        </b-col>
        <!-- <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          Code de la demande:&nbsp;
          <strong>
            {{
              validOutput.code_demande != null ? validOutput.code_demande : "-"
            }}
          </strong>
        </b-col> -->
        <!-- <b-col cols="12" xl="6" class="mb-1">
          Montant:
          <strong>
            {{ validOutput.montant != null ? validOutput.montant : "-" }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          Date de paiement:
          <strong>
            {{
              validOutput.date_paiement != null
              ? formattedDate(validOutput.date_paiement)
              : "-"
            }}
          </strong>
        </b-col> -->
      </b-row>
    </b-card-body>
    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong> IV-{{$t('demand_detail.attachment')}}</strong>
          <b-button
             v-if="showCheckButton && showCheckButtons"
            v-b-tooltip.hover.top.v-dark="$t('demand_detail.sec_validate')"
            variant="light"
            class="btn-icon"
            size="sm"
            @click="update_object('files', validOutput.check_object)"
          >
            <i class="las la-check iconSmall" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <!-- <i
        v-if=""
        class="las la-dot-circle icon_primary my-auto"
      /> -->
      <div v-if="validOutput.data.files != null">
        <b-button
          variant="primary"
          @click="downloadFile(validOutput.data.files)"
        >
          {{
            check_object && check_object.files === true
              ? $t('demand_detail.validated_attachment')
              : $t('demand_detail.download_attachment')
          }}
          <i v-if="!isDownloading" class="las la-file-download la-lg" />
          <b-spinner v-else small />
        </b-button>
        <hr class="hr-primary" />
        <!-- affichage des fichiers -->
        <span class="h4 ml-1">
          {{ $t('demand_detail.list_attachment_details')  }}
        </span>
        <span>
          <DownloadFile
            :documents="validOutput.attached_files"
            :is_demande="true"
            @on_would_see_file="would_see_file($event)"
          />
        </span>

        <hr class="hr-primary" />
        <!-- validation des pièces jointes -->
        <app-collapse
              >
                <app-collapse-item>
                  <!-- title -->
                  <template slot="header">
                    <span
                      class="h4 pointer-cursor"
                    >
                      <span> {{ $t('demand_detail.Please_validate_attachments_related_request')  }}</span>
                    </span>
                  </template>
                  <!-- content -->
                   <div v-if="validOutput.attached_files.length > 0">
                  <b-row
          v-for="(value, key) in validOutput.check_object"
          :key="key"
          class="mt-1 ml-1"
        >
          {{ key }} : <strong>{{ value === true ? 'Validé' : 'Non validé' }}</strong>
          <b-form-checkbox
          v-model="validOutput.check_object[key]"
            class="ml-1"
            @change="update_object(key,validOutput.check_object)"
            v-if="showCheckButtons"
          />
        </b-row>
      </div>
      <div v-else>{{ $t('demand_detail.no_file') }}</div>
                </app-collapse-item>
              </app-collapse>
      </div>
      <div v-else>{{$t('demand_detail.no_file')}}</div>
      <!-- <DownloadFile
        :documents="validOutput.attached_files"
        :is_demande="true"
        @on_would_see_file="would_see_file($event)"
      /> -->
    </b-card-body>

    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong> V-{{$t('demand_detail.commission_decision')}} </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1">
          {{$t('demand_detail.type_guide')}}&nbsp;
          <strong>
            {{
              validOutput.type != null ? validOutput.type : '-'
            }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          {{$t('demand_detail.category')}}&nbsp;
          <strong>
            {{
              validOutput.categorie != null ? validOutput.categorie : '-'
            }}
          </strong>
        </b-col>
        <!-- <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          Code de la demande:&nbsp;
          <strong>
            {{
              validOutput.code_demande != null ? validOutput.code_demande : "-"
            }}
          </strong>
        </b-col> -->
        <!-- <b-col cols="12" xl="6" class="mb-1">
          Montant:
          <strong>
            {{ validOutput.montant != null ? validOutput.montant : "-" }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          Date de paiement:
          <strong>
            {{
              validOutput.date_paiement != null
              ? formattedDate(validOutput.date_paiement)
              : "-"
            }}
          </strong>
        </b-col> -->
      </b-row>
      
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BPopover,
  BButton,
  VBTooltip,
  BTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'
import AppLogo from '@/components/AppLogo.vue'
import RequestDetailsCheckComponent from '@/components/RequestDetailsCheckComponent.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import paramsStoreModule from '@/store/params'
import DownloadFile from '@/components/DownloadFile.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import Login from '@/views/pages/authentication/Login.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    vSelect,
    Logo,
    BPopover,
    BButton,
    AppLogo,
    RequestDetailsCheckComponent,
    BTooltip,
    DownloadFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    validOutput: {
      type: Object,
      default: () => {},
    },
    showCheckButtons: {
      type: Boolean,
      default: () => false,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canUpdateResquestData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options_check: [
        {
          title: 'options_check.title1',
          value: true,
          variant: 'primary',
          icon: 'la-check',
        },
        {
          title: 'options_check.title2',
          value: false,
          variant: 'danger',
          icon: 'la-times',
        },
        {
          title: 'options_check.title3',
          value: 'to_be_confirmed',
          variant: 'secondary',
          icon: 'la-question',
        },
      ],

      listValuesTypeEtablissement: [
        {
          label: 'type_establishment.label1',
          value: 'type_establishment.label1',
        },
        {
          label: 'type_establishment.label2',
          value: 'type_establishment.label2',
        },
        {
          label: 'type_establishment.label3',
          value: 'type_establishment.label3',
        },
        {
          label: 'type_establishment.label4',
          value: 'type_establishment.label4',
        },
        {
          label: 'type_establishment.label5',
          value: 'type_establishment.label5',
        },
        {
          label: 'type_establishment.label6',
          value: 'type_establishment.label6',
        },
        {
          label: 'type_establishment.label7',
          value: 'type_establishment.label7',
        },
        {
          label: 'type_establishment.label8',
          value: 'type_establishment.label8',
        },
      ],

      listValuesUniteLogement: [
        // {
        //   label: '',

        //   value: '',
        // },
        {
          label: 'unite_logement.label1',

          value: 'unite_logement.label1',
        },
        {
          label: 'unite_logement.label2',

          value: 'unite_logement.label2',
        },
        {
          label: 'unite_logement.label3',

          value: 'unite_logement.label3',
        },
        {
          label: 'unite_logement.label4',

          value: 'unite_logement.label4',
        },
        {
          label: 'unite_logement.label5',

          value: 'unite_logement.label5',
        },
        {
          label: 'unite_logement.label6',

          value: 'unite_logement.label6',
        },
        {
          label: 'unite_logement.label7',

          value: 'unite_logement.label7',
        },
      ],

      listValuesClassement: [
        {
          label: 'classement_value.label1',

          value: 'classement_value.label1',
        },
        {
          label: 'classement_value.label2',

          value: 'classement_value.label2',
        },
        {
          label: 'classement_value.label3',

          value: 'classement_value.label3',
        },
        {
          label: 'classement_value.label4',

          value: 'classement_value.label4',
        },
        {
          label: 'classement_value.label5',

          value: 'classement_value.label5',
        },
      ],

      current_check_value: null,
      current_check_attribut: null,
      isDownloading:false,
      userData: {},
      showCheckButton:false,
      shouldShowCheckBox:false,
    }
  },
  computed: {
    ...mapGetters('params', {
      getter_current_check_value: 'getter_current_check_value',
    }),
    // --------------------------------------------------------
    check_object() {
      return this.validOutput && this.validOutput.check_object
    },
  },
  setup() {
    const requiredStoreModules = [{ path: 'params', module: paramsStoreModule }]
    registerStoreModule(requiredStoreModules)
  },
  watch: {
    $route: {
      immediate: true,
       handler(val, old) {
        this.userData = localstorageService.getUserData()
        if(this.userData.roles.some(role => role.alias === "UAT" || role.alias === "AUAT"))
        this.showCheckButton = true
        
        console.log("val::: ⭕️⭕️⭕️",this.userData)
        console.log(this.showCheckButton)
      },
    },
    getter_current_check_value: {
      immediate: false,
      async handler(val, old) {

        // console.log("val::: ⭕️⭕️⭕️", val, this.current_check_attribut);
        this.check_object[this.current_check_attribut] =
          this.getter_current_check_value
        this.$emit('on_change_check_object', this.check_object)
      },
    },
  },
  mounted() {
    console.log('this.infos::: ', this.validOutput)
  },
  methods: {
    formattedDate(dateValue) {
      // Create a new Date object from the original date string
      const date = new Date(dateValue)
      // Format the date manually
      const formattedDateString = `${date
        .getDate()
        .toString()
        .padStart(2, '0')}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getFullYear()} ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
      return formattedDateString
    },
    renderTooltip() {
      const props = {
        model: this.current_check_value,
      }
      const requestDetailsCheckComponent = this.$createElement(
        'requestDetailsCheckComponent',
        { props }
      )
      return requestDetailsCheckComponent
    },
    hoverTooltip(current_model, attribut) {
      this.current_check_value = current_model
      this.current_check_attribut = attribut
    },
    async downloadFile(fileUrl) {
      console.log('🔴🔴🔴response::: ')
      this.isDownloading = true
      // axios
      //   .get(fileUrl, {
      //     responseType: 'blob', // Specify blob response type for binary data
      //   })
      //   .then((response) => {
      //     const fileName = response.headers['content-disposition']
      //       ?.split('filename=')[1]
      //       ?.trim()
      //     const suggestedFileName = fileName || 'download.zip' // Set default filename if not provided

      //     const blob = new Blob([response.data], { type: 'application/zip' }) // Create Blob object with correct content type
      //     const link = document.createElement('a')
      //     link.href = URL.createObjectURL(blob)
      //     link.setAttribute('download', suggestedFileName)
      //     link.click()

      //     // Optional: Revoke object URL after download (important for cleanup)
      //     URL.revokeObjectURL(link.href)
      //     this.isDownloading = false
      //   })
      //   .catch((error) => {
      //     console.error('Error downloading ZIP:', error)
      //     // Handle download errors gracefully (e.g., display an error message to the user)
      //     this.isDownloading = false
      //   })

      try {
        // const response = await fetch(`${fileUrl}`)
        // const blob = await response.blob()
        // // ------------------------------------
        // // const custom_blob = await this.convertReadableStreamToBlob(
        // //   response.body
        // // );
        // // ------------------------------------
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.download = 'fichier.pdf'
        // link.click()
        // URL.revokeObjectURL(link.href)
        const link = document.createElement('a')
        link.href = fileUrl
        // link.target = '_blank'
        link.download = 'demandes'

        // Simulate a click on the element <a>
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.isDownloading = false
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors du téléchargement du fichier :",
          error
        )
        this.isDownloading = false
        return null
      }
    },
    selectAll() {
      for (const key in this.check_object) {
        if (this.check_object.hasOwnProperty(key)) {
          this.check_object[key] = this.options_check[0].value
          this.hoverTooltip(this.check_object[key], key)
          this.$emit('on_change_check_object', this.check_object)
        }
      }
    },
    update_object(attribut, value) {
      // this.$emit('on_change_object', this.validOutput)
      if (attribut === 'files') {
        for (let key in value) {
        if (value.hasOwnProperty(key)) {
            value[key] = true; // Update the value
        }
    }
    console.log("value",value)
      }
       this.$emit("on_change_object", { attribut, value });
    },
    would_see_file(data) {
      // console.log("🚧 data::: on_would_see_file", data);
      this.$emit('on_would_see_file', data)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.title-background {
  background-color: #ababab;
}

.tableHeadFontSize {
  font-size: 10px;
}

.bd-none {
  border: none;
}
</style>
